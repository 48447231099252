import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'

const Financing = ({item}) =>{
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <a href='https://www.hfsfinancial.net/promo/6496188e8649edce713c155d/' target='_blank' rel='noopener noreferrer'>
        <div className='bg-orange-400 m-[10%] p-10 rounded flex flex-wrap'>
            <div className='w-full md:w-[50%]'>
                <img src={rpdata?.stock?.[0]} alt=''/>
            </div>
            <div className='w-full md:w-[50%]'>
                <h2>{rpdata?.dbSlogan?.[6].slogan}</h2>
            </div>
        </div>
        </a>
    )
}

export default Financing;